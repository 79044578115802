<template>
  <div class="inside-page-content">
    <div class="paper all-center">
      <div class="top-row">
        <img :src="detailData.pictureUrl" class="top-img"/>
        <div class="top-info">
          <div class="top-name">
            <label class="type">{{detailData.insuranceName}}</label>
          </div>
          <div class="top-type">{{insuranceTypeDic[detailData.insuranceType]}}</div>
          <div class="condition">
            <label>{{detailData.companyName}}</label>
          </div>
        </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div class="detail-content">
        <div class="content-item">
          <div class="content-title">简要介绍</div>
          <div class="content-text">{{detailData.insuranceDesc}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">适用范围</div>
          <div class="content-text">{{detailData.applicableScope}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">申请流程</div>
          <div class="content-text">{{detailData.applyProcess}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">申请条件</div>
          <div class="content-text">{{detailData.applyCondition}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">申请所需资料</div>
          <div class="content-text">{{detailData.applyMaterials}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'insureDetail',
  components: {
    TopFilter
  },
  data() {
    return {
      insuranceTypeDic:{1:"人身保险",2:"财产保险",3:"责任保险"},
      id:null,
      detailData:{}
    }
  },
  computed:{
  },
  created(options) {
    this.id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    getDetail() {
      this.$api.technologyFinance.insuranceDetail(this.id).then((res) => {
        this.detailData = res.data.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  .top-img{
    height:67px;
    width:117px;
  }
  .top-info{
    height: 100%;
    flex: 1;
    margin-left: 40px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-around;
    .top-name{
      display: flex;
      align-items: center;
      flex-direction: row;
      .type{
        font-weight: 700;
      }
    }
    .top-type{
      font-size: 12px;
      color: #F1AF53;
    }
    .condition{
      font-size: 14px;
      display: flex;
      flex-direction: column;
    }
  }
}
.desc {
  display: flex;
  flex-direction: row;
  padding: 20px 80px;
  justify-content: space-around;
  .desc-item{
    display: flex;
    flex-direction: column;
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .info-text{
      padding-top: 10px;
      font-size: 14px;
      font-weight: 200;
    }
  }
}
.divider{
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.detail-content{
  margin: 20px 80px;
  .content-item{
    margin: 40px 0;
    .content-title{
      border-left: 5px solid blue;
      padding-left: 10px;
      font-weight: 700;
    }
    .content-text{
      margin-top: 10px;
      font-size: 14px;
    }
  }

}

</style>
